.kgb-advance-search-drawer .ant-drawer-content-wrapper {
  width: 320px;
}
.kgb-advance-search-drawer .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-select {
  width: 100%;
}
.kgb-advance-search-drawer .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-col {
  margin-bottom: 10px;
}
.kgb-advance-search-drawer .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body .ant-calendar-picker {
  width: 100%;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  color: #788195;
}
.ant-modal-content .ant-modal-footer .kgb-modal-btn-cancel {
  text-decoration: underline;
  margin-right: 30px;
  cursor: pointer;
  font-weight: 500;
}
.kgb-advance-search-drawer .drawer-clearall {
  text-align: right;
  color: #4482FF;
  text-decoration: underline;
  cursor: pointer;
}
.kgb-top-notify #notify-symbol {
  width: 22px;
  height: 22px;
  top: -10px;
  background-color: #de350b;
}
.main-loading {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
